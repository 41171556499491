
import React from 'react'

import { Box, Button } from '@mui/material'

import Card from '@mui/material/Card';

import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';



import NavBar from '../../components/navBar';
import Sidenav from '../../components/sidenav'
import DniTable from './dni_number_table';

const AdminDni = () => {
  return (
    <>
      <NavBar/>
      <Box height={120} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav/>
        
        <Box component="main" sx={{ flexGrow: 2, p: 3 }}>
       



            <DniTable/>
        
        </Box>
      </Box>


    </>

  )
}
export default AdminDni
