



import Card from '@mui/material/Card';

import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';


import UserForm from '../../Forms/Form';
import CreatUserDialog from '../../Forms/button.Dialog';

import NavBar from '../../components/navBar';
import Sidenav from '../../components/sidenav'
import React, { useState, useEffect } from 'react';
import { Box, TextField, Button } from '@mui/material';
import axios from '../../api/axios';

const AdminMangeProfile = () => {

  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    state: '',
    mobileNumber: '',
    city: '',
    role: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userId = 'userId'; // Replace 'userId' with the actual user ID
        const response = await axios.get(`your-backend-api/users/${userId}`, {
          headers: {
            Authorization: `Bearer your-token`, // Replace 'your-token' with the actual token
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e, key) => {
    setUserData({ ...userData, [key]: e.target.value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`your-backend-api/users/${userData.id}`, userData, {
        headers: {
          Authorization: `Bearer your-token`, // Replace 'your-token' with the actual token
        },
      });
      console.log('User details updated successfully');
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };




    
  return (
    <>
      <NavBar/>
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav/>
        <Box component="form" sx={{ }}>
         
          <Box
      component="form"
      onSubmit={handleSave}
      sx={{
        '& .MuiTextField-root': { m: 2, width: '20ch'},
      }}
      noValidate
      autoComplete="off"
    >
      {Object.entries(userData).map(([key, value]) => (
        <TextField
          key={key}
          id={`outlined-${key}-input`}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          value={value}
          onChange={(e) => handleInputChange(e, key)}
        />
      ))}
      <Button type="submit">Submit</Button>
    </Box>
        
        </Box>
      </Box>


    </>

  )
}
export default AdminMangeProfile
