 import React ,{ useState, useEffect }from 'react'

import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography';
import NavBar from '../../components/navBar';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import "../../css/dash.css"
import PersonIcon from '@mui/icons-material/Person';
import CampaignIcon from '@mui/icons-material/Campaign';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import axios from '../../api/axios';
import LoadingOverlay from '../../loader/loader';

const AdminDashboard = () => {

   const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBackendData();
  }, []);

  const fetchBackendData = async () => {
    try {
      const response = await axios.get('obd/details'); // Assuming the endpoint is '/api/stats'
      setData(response.data);
      console.log("Response:", response.data.resellerCount); // Log the response data
    } catch (error) {
      console.error('Error fetching data:', error); // Log any errors
    }  finally {
            setLoading(false); // Set loading to false regardless of success or error
          }
    
  };
  return (
    <>
    <NavBar/>
    <Box height={70}/>
    <Box sx={{ display: 'flex' }}>
    <Sidenav/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Grid container spacing={2}>
        <Grid item xs={8}>
        <Stack spacing={2} direction={"row"}>
        <Card sx={{ minWidth: 33 +"%", height:120  }} id='gradientCard'  >
      <CardContent>
        
        <div className='icon'>
        <PersonIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
  
          
        {data.userCount}
        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
    Total User
    
        </Typography>
      </CardContent>
    </Card>
    <Card  sx={{ minWidth: 33 +"%", height:120 }} id='gradientCard'>
      <CardContent>
      <div className='icon'>
        <CampaignIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
        {data.campaignCount}

        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
       Total Campaign
        </Typography>
      </CardContent>
    </Card>
    <Card sx={{ minWidth: 33 +"%", height:120}} id='gradientCard'>
      <CardContent>
      <div className='icon'>
        <CoPresentIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
        {data.resellerCount}
        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
        Re-seller
        </Typography>
      </CardContent>
    </Card>
    </Stack>
        </Grid>
      </Grid>

       
      </Box>
    </Box>
    

    </>
   
  )
}
export default AdminDashboard
