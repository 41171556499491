import React, { useEffect, useState } from 'react';
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignListTable from '../../tables/obdTable';
import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';
import UserCampaignListTable from '../../User/User_OBD/user_obd_table';
import ResellerNavBar from '../reseller_navigation/reseller_nav_bar';
import ResellerSidenav from '../reseller_navigation/reseller_side_navigation';
import axios from '../../api/axios';

const ResllerObdReport = () => {
    const [parameterNames, setParameterNames] = useState([
      'CAMPAIGN_ID',
      'SERVICE_TYPE',
      'CALL_ID',
      'DNI',
      'A_PARTY_NO',
      'CALL_START_TIME',
      'A_PARTY_DIAL_START_TIME',
      'A_PARTY_DIAL_END_TIME',
      'A_PARTY_CONNECTED_TIME',
      'A_DIAL_STATUS',
      'A_PARTY_END_TIME',
      'OG_DURATION'
    ]);
  
    const [responseData, setResponseData] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Construct the request data object dynamically
          const requestData = {};
          parameterNames.forEach(name => {
            requestData[name] = ''; // You can set a default value if needed
          });
  
          // Make the POST request using the dynamic requestData
          const response = await axios.post('obd/getdata', requestData);
  
          setResponseData(response.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [parameterNames]);




  return (
    <>
      <ResellerNavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <ResellerSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* <div className='user-button'>
            <Link to="/reseller-create-obd" style={{ textDecoration: 'none' }}>
              <ReUseableButton style={{ background: '#1976D2', margin: '0 0 24px 0', color: '#fff', fontFamily: 'Inter', fontSize: 13 }} >
                Create Campaign
              </ReUseableButton>
            </Link>

          </div>
          <Card id='gradientCard'  >
            <UserCampaignListTable />
          </Card> */}

<div>
      <h1>Request Data:</h1>
      <pre>{JSON.stringify(parameterNames, null, 2)}</pre>

      <h1>Response Data:</h1>
      <pre>{JSON.stringify(responseData, null, 2)}</pre>
    </div>
        </Box>
      </Box>


    </>

  )
}
export default ResllerObdReport
