import React from 'react'
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignForm from './Campaign_Form';





const Home = () => {
  return (
    <>
      <NavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card  >
            <CampaignForm />
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default Home
