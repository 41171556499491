
import React from 'react'

import { Box } from '@mui/material'

import Card from '@mui/material/Card';

import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';

import AdminUserListTable from './admin_credit_list';
import UserForm from '../../Forms/Form';
import CreatUserDialog from '../../Forms/button.Dialog';

import NavBar from '../../components/navBar';
import Sidenav from '../../components/sidenav'


const AdminCreditUser = () => {
  return (
    <>
      <NavBar/>
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <Sidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card id='gradientCard'  >
            <AdminUserListTable/>
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default AdminCreditUser
