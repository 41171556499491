import React from 'react'
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignForm from "../../pages/OBD_Campiagn/Campaign_Form";
import UserSidenav from '../UserNavigation/userSideNav';
import UserCampaignForm from './user_campaign_form';






const UserCampaign = () => {
  return (
    <>
      <NavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <UserSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card  >
            <UserCampaignForm/>
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default UserCampaign
