// import React, { useState, useEffect } from 'react';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import "../css/userTable.css"
// import axios from '../api/axios';
// import { Button } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import GetAppIcon from '@mui/icons-material/GetApp';
// import CloudDownloadIcon from '@mui/icons-material/CloudUpload'
// import { styled } from '@mui/material/styles';
// import LoadingOverlay from '../loader/loader';
// import { PlayArrow } from '@mui/icons-material';
// import DownloadIcon from '@mui/icons-material/Download';
// import { generate_User_Report_CSV,generate_Report_CSV } from '../api/csv_handler';
// import AudioPlayer from './obdAudio';
// import StatusComponent from '../Assests/status';
// const VisuallyHiddenInput = styled('input')({
//   clip: 'rect(0 0 0 0)',
//   clipPath: 'inset(50%)',
//   height: 1,
//   overflow: 'hidden',
//   position: 'absolute',
//   bottom: 0,
//   left: 0,
//   whiteSpace: 'nowrap',
//   width: 1,
// });



// export default function CampaignListTable() {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true); 
//   const token= localStorage.getItem('token')   




  

//   const handleUserDownloadReport = async (campaign_ref_Id) => {
//     setLoading(true);

//     try {
//       const response = await axios.get(`report/user-generate-csv?campaignRefId=${campaign_ref_Id}`,
//       {
//         headers:{
//           authorization:token
//         }
//       }
//       )
//   const data = response.data
   
// const fileName = `report_${campaign_ref_Id}.csv`; 
//   const selectedColumns = ["A_PARTY_NO", "A_DIAL_STATUS", "obdcampaignname","creditAction","createdAt"]
//   generate_User_Report_CSV(data, selectedColumns, fileName);
//   } catch (error) {
//       console.error('Error downloading report:', error);
//       console.error('Error initiating CSV download');
//   }finally {
//     setLoading(false); 
//   }
//   }  

//   const handleDownloadReport = async (campaign_ref_Id) => {
//     setLoading(true);

//     try {
//       const response = await axios.get(`report/generate-csv?campaignRefId=${campaign_ref_Id}`,
//       )
//   const data = response.data
   
// const fileName = `report_${campaign_ref_Id}.csv`; 
//   const selectedColumns = ["CAMPAIGN_REF_ID","A_PARTY_NO","A_DIAL_STATUS","CALL_ID","CALL_START_TIME","A_PARTY_DIAL_END_TIME","A_PARTY_CONNECTED_TIME","A_PARTY_END_TIME","OG_DURATION","DTMF"]
//   generate_Report_CSV(data, selectedColumns, fileName);
//   } catch (error) {
//       console.error('Error downloading report:', error);
//       console.error('Error initiating CSV download');
//   }finally {
//     setLoading(false);
//   }
//   }  
  
//   useEffect(() => {
//     fetchBackendData();
//   }, []);
// const OBD_URL="obd/getlist"
//   const fetchBackendData = async() => {
//     try {
//       const response = await axios.get(OBD_URL);
//       console.log("data",response.data);
//       setData(response.data); // Assuming the array is in response.data
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }finally {
//       setLoading(false); // Set loading to false regardless of success or error
//     }
//   };

//   return (
//     <LoadingOverlay loading={loading}>
//     <TableContainer component={Paper}>
//     <Table sx={{ minWidth: 650, }}>
//       <TableHead>

//         <TableRow className='table-head'>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Sr. No</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Campaign Name</TableCell>
//           {/* <TableCell sx={{textAlign:"center"}} className='table-title'>Campaign Type</TableCell> */}
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Role</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Username</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Audio</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Status</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>User Report</TableCell>
//           <TableCell sx={{textAlign:"center"}} className='table-title'>Custom Report</TableCell>




//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {data.map((row, index) => (
//           <TableRow key={index}>
//             <TableCell>{index + 1}</TableCell> {/* Add 1 to start from 1 */}
//             <TableCell  sx={{textAlign:"center",fontSize:13}} component="th" scope="row">
//               {row.obdcampaignname}
//             </TableCell>
//             <TableCell  sx={{textAlign:"center",fontSize:13}}>{row.role}</TableCell>
//             <TableCell  sx={{textAlign:"center",fontSize:13}}>{row.createdByUsername}</TableCell>

//             {/* <TableCell  sx={{textAlign:"center",fontSize:13}}>{row.campaign_Type}</TableCell> */}
//             <TableCell  sx={{textAlign:"center",fontSize:13}}> <IconButton>
//          <AudioPlayer audioData={row.audio.data} />
//         </IconButton>{row.audio.filename}</TableCell>
//             <TableCell sx={{textAlign:"center",fontSize:13}}><StatusComponent campaignRefIds={[row.campaign_ref_Id]} /></TableCell>

//             <TableCell>
//             <Button sx={{fontSize:9,fontWeight:600}} variant="outlined" color="primary" onClick={() => handleDownloadReport(row.campaign_ref_Id)}>
//             <DownloadIcon sx={{fontSize:18}} />   Download
//                   </Button>

//       </TableCell>
//       <TableCell>
//       <TableCell>
//       <Button sx={{fontSize:9,fontWeight:600, marginLeft:1 }} variant="outlined" color="primary" onClick={() => handleUserDownloadReport(row.campaign_ref_Id)}>
//             <DownloadIcon sx={{fontSize:18, marginRight:1}} />   Download
//                   </Button>

//       </TableCell>
//       </TableCell>

//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   </TableContainer>
//   </LoadingOverlay>
//   );
// }



import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import AudioPlayer from './obdAudio';
import StatusComponent from '../Assests/status';
import LoadingOverlay from '../loader/loader';
import axios from '../api/axios';
import { generate_Report_CSV } from '../api/csv_handler';

export default function CampaignListTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    fetchBackendData();
  }, [page, limit]); // Reload data when page or limit changes

  const fetchBackendData = async () => {
    try {
      const response = await axios.get(`/obd/getlist?page=${page}&limit=${limit}`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadReport = async (campaignRefId, isUserReport) => {
    setLoading(true);

    try {
      const endpoint = isUserReport ? 'report/user-generate-csv' : 'report/generate-csv';
      const response = await axios.get(endpoint, {
        params: { campaignRefId },
      });

      const data = response.data;
      const fileName = `report_${campaignRefId}.csv`;

       const selectedColumns = ["CAMPAIGN_REF_ID","A_PARTY_NO","A_DIAL_STATUS","CALL_ID","CALL_START_TIME","A_PARTY_DIAL_END_TIME","A_PARTY_CONNECTED_TIME","A_PARTY_END_TIME","OG_DURATION","DTMF"]
  generate_Report_CSV(data, selectedColumns, fileName);
    } catch (error) {
      console.error('Error downloading report:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPage(page + 1); // Increment page number
  };

  return (
    <LoadingOverlay loading={loading}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow className='table-head'>
              <TableCell align='center'>Sr. No</TableCell>
              <TableCell align='center'>Campaign Name</TableCell>
              <TableCell align='center'>Role</TableCell>
              <TableCell align='center'>Username</TableCell>
              <TableCell align='center'>Audio</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Custom Report</TableCell>
              <TableCell align='center'>User Report</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell align='center'>{(page - 1) * limit + index + 1}</TableCell>
                <TableCell align='center' style={{ fontSize: 13 }}>{row.obdcampaignname}</TableCell>
                <TableCell align='center' style={{ fontSize: 13 }}>{row.role}</TableCell>
                <TableCell align='center' style={{ fontSize: 13 }}>{row.createdByUsername}</TableCell>
                <TableCell align='center' style={{ fontSize: 13 }}>
                  <IconButton>
                    <AudioPlayer audioData={row.audio.data} />
                  </IconButton>
                  {row.audio.filename}
                </TableCell>
                <TableCell align='center' style={{ fontSize: 13 }}>
                  <StatusComponent campaignRefIds={[row.campaign_ref_Id]} />
                </TableCell>
                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => handleDownloadReport(row.campaign_ref_Id, false)}
                  >
                    <DownloadIcon /> Download
                  </Button>
                </TableCell>
                <TableCell align='center'>
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => handleDownloadReport(row.campaign_ref_Id, true)}
                  >
                    <DownloadIcon /> Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button
          variant='contained'
          color='primary'
          onClick={handleNextPage}
          disabled={loading} // Disable button while loading
        >
          Next Page
        </Button>
      </div>
    </LoadingOverlay>
  );
}
