// import React, { useState, useEffect } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// // import "../css/userTable.css"
// import axios from "../../api/axios";
// import { generate_User_Report_CSV } from "../../api/csv_handler";
// import { Card, Container, IconButton } from "@mui/material";
// import GetAppIcon from '@mui/icons-material/GetApp';
// import AudioPlayer from "../../tables/obdAudio";
// import StatusComponent from "../../Assests/status";
// import RePushButton from "../../Assests/re-pushService";


// export default function UserCampaignListTable() {



//   const handleDownloadUserReport = async (campaign_ref_Id) => {
//     try {
//       const token= localStorage.getItem('token')
//       console.log("token",token);
//         const response = await axios.get(`report/user-generate-csv?campaignRefId=${campaign_ref_Id}`,{
//           headers:{
//             authorization:token
//           }
//         }
//         )
//     const data = response.data
     
//   const fileName = `report_${campaign_ref_Id}.csv`; 
//     const selectedColumns = ["A_PARTY_NO", "A_DIAL_STATUS", "obdcampaignname","creditAction","createdAt"]
//     generate_User_Report_CSV(data, selectedColumns, fileName);
//     } catch (error) {
//         console.error('Error downloading report:', error);
//         console.error('Error initiating CSV download');
//     }
//   }




//   const [data, setData] = useState([]);

//   useEffect(() => {
//     fetchBackendData();
//   }, []);
//   const fetchBackendData = async () => {
//     try {
//       const token = localStorage.getItem("token");
//       const OBD_URL = 'user-campaigns';
//       const response = await axios.get(OBD_URL, {
//         headers: {
//           authorization: token,
//         },
//       });
//       setData(response.data); 
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ minWidth: 650 }} aria-label="caption table">
       
//         <TableHead>
//           <TableRow className="table-head">
//             <TableCell sx={{textAlign:"center"}} className="table-title">Sr. No</TableCell>
//             <TableCell sx={{textAlign:"center"}} className="table-title">Campaign Name</TableCell>
//             {/* <TableCell className="table-title">Campaign Type</TableCell> */}
//             <TableCell sx={{textAlign:"center"}} className="table-title">Audio</TableCell>
//             <TableCell sx={{textAlign:"center"}} className="table-title">Status</TableCell>
//             <TableCell sx={{textAlign:"center"}} className="table-title">Report</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {data.map((row, index) => (
//             <TableRow key={index}>
//               <TableCell sx={{textAlign:"center",fontSize:11}}>{index + 1}</TableCell> {/* Add 1 to start from 1 */}
//               <TableCell  sx={{textAlign:"center",fontSize:11}} component="th" scope="row">
//                 {row.obdcampaignname}
//               </TableCell>
//               {/* <TableCell>{row.campaign_Type}</TableCell> */}
//               <TableCell sx={{textAlign:"center",fontSize:11}}> <IconButton>
            
//          <AudioPlayer audioData={row.audio.data} />
//         </IconButton>{row.audio.filename}</TableCell>
//               <TableCell sx={{textAlign:"center",fontSize:11}}><StatusComponent campaignRefIds={[row.campaign_ref_Id]} /></TableCell>
//               <TableCell sx={{ alignItems:"center",fontSize:11}}>
//                 <Container sx={{display:'flex', alignItems:'center'}}>
//             <IconButton onClick={()=>handleDownloadUserReport(row.campaign_ref_Id)} color="primary">
//       <GetAppIcon />
//     </IconButton>
//     <RePushButton campaignRefIds={[row.campaign_ref_Id]} createBy={[row.createdBy]} />
//     </Container>
//       </TableCell>    
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }



import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from '@mui/icons-material/GetApp';
import AudioPlayer from "../../tables/obdAudio";
import StatusComponent from "../../Assests/status";
import RePushButton from "../../Assests/re-pushService";
import axios from "../../api/axios";
import { generate_User_Report_CSV } from "../../api/csv_handler";

export default function UserCampaignListTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    fetchBackendData();
  }, [page, limit]);

  const fetchBackendData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`user-campaigns?page=${page}&limit=${limit}`, {
        headers: {
          authorization: token,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadUserReport = async (campaign_ref_Id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`report/user-generate-csv?campaignRefId=${campaign_ref_Id}`, {
        headers: {
          authorization: token,
        },
      });
      const data = response.data;
      const fileName = `report_${campaign_ref_Id}.csv`;
      const selectedColumns = ["A_PARTY_NO", "A_DIAL_STATUS", "obdcampaignname", "creditAction", "createdAt"];
      generate_User_Report_CSV(data, selectedColumns, fileName);
    } catch (error) {
      console.error('Error downloading report:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow className="table-head">
            <TableCell sx={{textAlign:"center"}} className="table-title">Sr. No</TableCell>
            <TableCell sx={{textAlign:"center"}} className="table-title">Campaign Name</TableCell>
            <TableCell sx={{textAlign:"center"}} className="table-title">Audio</TableCell>
            <TableCell sx={{textAlign:"center"}} className="table-title">Status</TableCell>
            <TableCell sx={{textAlign:"center"}} className="table-title">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{textAlign:"center",fontSize:11}}>{(page - 1) * limit + index + 1}</TableCell>
              <TableCell  sx={{textAlign:"center",fontSize:11}} component="th" scope="row">
                {row.obdcampaignname}
              </TableCell>
              <TableCell sx={{textAlign:"center",fontSize:11}}>
                <IconButton>
                  <AudioPlayer audioData={row.audio.data} />
                </IconButton>
                {row.audio.filename}
              </TableCell>
              <TableCell sx={{textAlign:"center",fontSize:11}}>
                <StatusComponent campaignRefIds={[row.campaign_ref_Id]} />
              </TableCell>
              <TableCell sx={{ alignItems:"center",fontSize:11}}>
                <IconButton onClick={() => handleDownloadUserReport(row.campaign_ref_Id)} color="primary">
                  <GetAppIcon />
                </IconButton>
                <RePushButton campaignRefIds={[row.campaign_ref_Id]} createBy={[row.createdBy]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>Previous Page</button>
        <span style={{ margin: '0 10px' }}>Page {page}</span>
        <button onClick={() => handlePageChange(page + 1)}>Next Page</button>
      </div>
    </TableContainer>
  );
}
