import React, { useState } from 'react';

import { Container, Typography, TextField, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import obdForm from "../../resller/css/obdForm.css"
import LoadingOverlay from '../../loader/loader';
import Alert from '@mui/material/Alert';




const UserCampaignForm=()=>{

  const number = 1234567890;

  // Function to download the number as a CSV file
  const downloadCSV = () => {
    const blob = new Blob([number], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'numbers.csv';
    link.click();
};

const [value , setValue]=useState(0)
// const navigate= Navigate()
const [isLoading, setIsLoading] = useState(false);
const [error, setError] = useState('');
const [selectedFile, setSelectedFile] = useState(null);

const navigate = useNavigate();
const handleFileChange = (event) => {
  const file = event.target.files[0];

  if (file && !file.type.includes('audio/mpeg')) {
    setError('Only .mp3 files are allowed.');
    setSelectedFile(null);
  } else {
    setError('');
    setSelectedFile(file);
  }
};
// if (file && !file.type.includes('.csv')) {
//   setError('Only .csv files are allowed.');
//   setSelectedFile(null);
// } else {
//   setError('');
//   setSelectedFile(file);
// }


const handleCsvFileChange = (event) => {
  const file = event.target.files[0];

 
};





  const handleSubmit=async(e)=>{
    setIsLoading(true);
    e.preventDefault()
    if (!selectedFile) {
      setError('Please select an .mp3 file.');
      return;
    }
    const formData= new FormData(e.currentTarget)
const newCampiagn= Object.fromEntries(formData)
    try {
      const token = localStorage.getItem('token');
   const response =  await axios.post(
          'obd/create-obd',
          newCampiagn,
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  authorization: token,
                },
            }
            );

          

            console.log("Response",response);
            
          alert('Campaign created successfully');

          navigate('/user-campaign-list')   
          
    } catch (error) {
      alert('Failed to create campaign. Please try again later.');
      navigate('/user-campaign-list')   

      // throw error(error)

    }
  }
  return(
    <>
    <LoadingOverlay loading={isLoading}>

    <div>
<form className='form' onSubmit={handleSubmit}>
  <h4>create campaign API</h4>
  {/* Campaign Name */}
  <div className='form-row'>
    <label htmlFor="Campaign Name" className='form-label'>
      Campaign Name
    </label>
    <input type="text" className='form-input' id='CampaigName' name='CampaigName' />
  </div>
  {/* Description */}
  <div className='form-row'>
    <label htmlFor="Description" className='form-label'>
      Campaign Description
    </label>
    <input type="text" className='form-input' id='description' name='description' />
  </div>


  {error && (
          <Alert severity="error">{error}</Alert>
        )}
  {/* Audio file */}
  <div className='form-row'>
    <label htmlFor="audioFile" className='form-label'>
      Upload Campaign Audio
    </label>
    <input type="file" accept='.mp3' className='form-input' id='audioFile' name='audioFile'
    onChange={handleFileChange} />
  </div>
{/* Number file */}
<div className='form-row'>
    <label htmlFor="numberFile" className='form-label'>
      Upload Campaign Number File
    </label>
    <input type="file" accept='.csv' className='form-input' id='numberFile' name='numberFile' 
     onChange={handleCsvFileChange}/>
  </div>
  <div className="form-row">
<TextField
                      id="numbers"  
                      name="numbers"
                      label="Enter Manual Numbers (One per line) Or Upload CSV"
                      multiline
                      rows={8}
                      fullWidth
                  />
           </div> 
  <Button className='btn-sample' variant="contained" onClick={downloadCSV} sx={{ marginBottom: '20px',width:'23%',fontSize:'10px' }}>
        Download Sample CSV
      </Button>
  <button type='submit'>Create Campaign</button>
</form>

    </div>
    </LoadingOverlay>
    </>
  )
}
export default UserCampaignForm;
