// LoadingOverlay.js

import React from 'react';
import { CircularProgress } from '@mui/material';

import './loader.css';

const LoadingOverlay = ({ loading, children }) => {
  return (
    <div className="loading-overlay-container">
      {loading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoadingOverlay;
