import React from 'react'
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignListTable from '../../tables/obdTable';
import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';
import UserSidenav from '../UserNavigation/userSideNav';
import UserCampaignListTable from './user_obd_table';

import UserNavBar from '../UserNavigation/user_nav_bar';



const UserObd = () => {
  return (
    <>
      <UserNavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <UserSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className='user-button'>
            <Link to="/create-user-campaign" style={{ textDecoration: 'none' }}>
              <ReUseableButton style={{ background: '#1976D2', margin: '0 0 24px 0', color: '#fff', fontFamily: 'Inter', fontSize: 13 }} >
                Create Campaign
              </ReUseableButton>
            </Link>

          </div>
          <Card id='gradientCard'  >
            <UserCampaignListTable />
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default UserObd
