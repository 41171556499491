import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button ,Dialog,DialogTitle,DialogContent,FormControlLabel,Checkbox,TextField, DialogActions} from "@mui/material";
import axios from "../../api/axios";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export default function AdminUserListTable() {
  const token= localStorage.getItem('token')
  const [open, setOpen] = useState(false);
  const [openMobileNumberDialog, setOpenMobileNumberDialog] = useState(false);
  const [openBulkDialog, setOpenBulkDialog] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');


  const handleClickOpenMobileNumberDialog = () => {
    setOpenMobileNumberDialog(true);
  };

  const handleMobileNumberDialogClose = () => {
    setOpenMobileNumberDialog(false);
  };

  const handleBulkDialogClose = () => {
    setOpenBulkDialog(false);
  };


  const handleSaveMobileNumber = () => {
    const createUrl = 'dni/add-dni';
    axios.post(createUrl, { mobileNumber }, {
      headers: {
        authorization: token
      }
    })
      .then(response => {
        console.log('API Response:', response.data);
        handleMobileNumberDialogClose();
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  };
 


  const handleFileChange = (event) => {
    // Handle file change logic here
    const file = event.target.files[0];
    console.log('Selected File:', file);
  };

  const handleSaveBulk = () => {
    // Handle save logic for bulk upload here
    handleBulkDialogClose();
  };

  const deleteNumber = async (userId) => {
    const deleteUrl = `dni/delete?userId=${userId}`; // Fix the query parameter syntax
    try {
      await axios.delete(deleteUrl);
      alert('Number deleted successfully');
      window.location.reload();
    } catch (error) {
      console.error('API error:', error);
    }
  };
  
//   const handleClosePopup = () => {
//     setOpenPopup(false);
//     setAddCreditChecked(false);
//     setRemoveCreditChecked(false);
//     setCreditAmount('');
//   };

//   const handleCheckboxChange = (type) => {
//     if (type === 'add') {
//       setAddCreditChecked(true);
//       setRemoveCreditChecked(false);
//     } else {
//       setRemoveCreditChecked(true);
//       setAddCreditChecked(false);
//     }
//   };

//   const handleApplyChanges = () => {
//     // Validate credit amount (only numbers, no decimals, no alphabets)
//     const isNumeric = /^\d+$/.test(creditAmount);
    
//     if (isNumeric) {
//       // Call your API to add or remove credit based on checkbox values and credit amount
//       const apiEndpoint = addCreditChecked ? '/update_credits' : '/reverse_credit_transfer';
//     const requestBody = {
//       userId: selectedUserId,
//       credits: parseInt(creditAmount, 10)  // Convert creditAmount to an integer
//     };

//     // Call your API to add or remove credit based on checkbox values and credit amount
//     axios.put(apiEndpoint,requestBody,{  headers: {
//       authorization: token,
//     }})
//       .then(() => {
//         alert('Credit Edit successfully');
//         window.location.reload();
//         handleClosePopup();

//       })
//       .catch(error => {
//         console.error('API error:', error);
//       });
//     } else {
//       // Show an error message or handle the invalid input as needed
//       console.error('Invalid credit amount. Please enter a valid number.');
//     }
//   };


const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleBulkClose = () => {
    setOpen(false);
  };


  const handleMobileNumberChange = (event) => {

    const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    const limitedInput = input.slice(0, 10); // Take only the first 10 digits
    setMobileNumber(limitedInput);
  };

  const handleSave = () => {
  const Create_Url="dni/add-dni"
    axios.post(Create_Url, { mobileNumber },{
        headers:{
            authorization:token
        }
    })
    .then(response => {
      console.log('API Response:', response.data);
      handleClose();
    })
    .catch(error => {
      console.error('API Error:', error);
    });   console.log('Mobile Number:', mobileNumber);
    handleClose();
  };



  const [data, setData] = useState([]);
  useEffect(() => {
    fetchBackendData();
    
  }, []);
  const fetchBackendData = async () => {
    try {
      const DNI_LIST = 'dni/get-list';
      const response = await axios.get(DNI_LIST, {
        headers: {
          authorization: token,
        },
      });

     console.log("rsponse",response);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');
      const formData = new FormData(e.currentTarget);

      const response = await axios.post(
        'dni/upload-dni',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: token,
          },
        }
      );

      alert('Dni upload  successfully');
      // Handle the response if needed

      // Redirect or navigate to a different page
      // navigate('/obd');
    } catch (error) {
      console.error('Error creating campaign:', error);
      // Handle the error, display a message, etc.
    }
  };

  return (
    <><div>
        <Stack sx={{ marginLeft: '68%', marginBottom: 3 }} direction="row" spacing={2}>
        <Button variant="contained" onClick={() => setOpenBulkDialog(true)}>
          <AddIcon />
          Bulk Upload
        </Button>
        <Button sx={{ marginLeft: '85%', marginBottom: 3 }} variant="contained" onClick={handleClickOpenMobileNumberDialog}>
          <AddIcon />
          Add Number
        </Button>
      </Stack>
      </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow className="table-head">
            <TableCell className="table-title">Sr. No</TableCell>
            <TableCell className="table-title">DNI Number</TableCell>
            <TableCell className="table-title">Time</TableCell>
            <TableCell className="table-title">Action</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell> {/* Add 1 to start from 1 */}
              <TableCell component="th" scope="row">
                {row.number}
              </TableCell>
              <TableCell>{row.role}</TableCell>
              

              <TableCell> <Button variant="outlined" color="primary" onClick={() => deleteNumber(row._id)}>
                  Delete
                  </Button>
             
                  </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

   

<Dialog open={openMobileNumberDialog} onClose={handleMobileNumberDialogClose}>
        <DialogTitle>Enter Mobile Number</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ marginTop: '20px' }}
            label="Mobile Number"
            type="tel"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMobileNumberDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveMobileNumber} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openBulkDialog} onClose={handleBulkDialogClose}>
  <DialogTitle>Upload CSV File</DialogTitle>
  <form onSubmit={handleSubmit}>
    <input
      type="file"
      accept=".csv"
      className="form-input"
      id="numberFile"
      name="numberFile"
      onChange={handleFileChange}
    />
    <DialogActions>
      <Button onClick={handleBulkDialogClose} color="primary">
        Cancel
      </Button>
      <Button type="submit" color="primary">
        Save
      </Button>
    </DialogActions>
  </form>
</Dialog>

    </>
  );
}


