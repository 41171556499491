import axios from "axios"
// import useEnv from "react-dotenv"

// const {REACT_APP_API_URL}=useEnv()

    export default axios.create({
    baseURL:'https://calls.ostpl.com/'
    // baseURL:'http://localhost:5001/'
    // baseURL:'https://coral-app-86fps.ondigitalocean.app/'
 
})