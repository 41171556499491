import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UpdateUserForm from './edit_profile_page';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateUser(UID) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  return (
    <React.Fragment>
        <div className='user-button'>
        <Button sx={{ bgcolor:'#1976D2', fontFamily: 'Inter',fontSize:9, color:' #fff',":hover":{
  bgcolor:'#2688e9'
}}} variant="outlined" onClick={handleClickOpen}>
       Profile
      </Button>
        </div>
     
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Edit User"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
           <UpdateUserForm  UserId={UID}  />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

