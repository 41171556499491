import React, { useState,useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';






const UpdateUserForm = (UserId) => {
    const UID=UserId.UserId.UID
    const [userData, setUserData] = useState({
        password: '',
        email: '',
        state: '',
        mobileNumber: '',
        cutting: '',
        role: '',
        username: '',
        city: '',
      });
      const [originalUserData, setOriginalUserData] = useState({
        password: '',
        email: '',
        state: '',
        mobileNumber: '',
        cutting: '',
        role: '',
        username: '',
        city: '',
      });
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`users?userId=${UID}`);
            const data  = response.data;
            setUserData({
              password: data.password || '',
              email: data.email || '',
              state: data.state || '',
              mobileNumber: data.mobileNumber || '',
              cutting: data.cutting_percentage || '',
              role: data.role || '',
              username: data.username || '',
              city: data.city || '',
            });

            
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
        };
    
        if (UID) {
          fetchData();
        }
      }, [UID]);

      const handleSave = async (e) => {
        e.preventDefault()
        try {
          const updatedUserData = {};
      
          // Check if each field is different and update if changed
          if (userData.email !== originalUserData.email) {
            updatedUserData.email = userData.email;
          }
          if (userData.mobileNumber !== originalUserData.mobileNumber) {
            updatedUserData.mobileNumber = userData.mobileNumber;
          }
          if (userData.cutting !== originalUserData.cutting) {
            updatedUserData.cutting = userData.cutting;
          }
          if (userData.city !== originalUserData.city) {
            updatedUserData.city = userData.city;
          }
          if (userData.state !== originalUserData.state) {
            updatedUserData.state = userData.state;
          }
          if (userData.password !== originalUserData.password) {
            updatedUserData.password = userData.password;
          }
          await axios.put(`/update-users/?userId=${UID}`, updatedUserData);
          window.location.reload();;
        } catch (error) {
          console.error('Error updating user data:', error);
        }
      };

 
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSave}
    >
      <div>
        <TextField
        //   required
          id="outlined-username-input"
          label="Username (Not Editable)"
          autoComplete="off"
          name='username'
          value={userData.username}
          InputProps={{ readOnly: true }}
        />
        <TextField
        //   required
          id="outlined-email-input"
          type="email"
          label="Email"
          name='email'
          value={userData.email}
          autoComplete="off"
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        />
        <TextField
        //   required
          id="outlined-password-input"
          label="Password"
          type="text"
          name='password'
          value={userData.password}
          autoComplete="current-password"
          onChange={(e) => setUserData({ ...userData, password: e.target.value })}
        />
        <TextField
   id="outlined-state-input"
   label="State"
   name='state'
   value={userData.state}
   onChange={(e) => setUserData({ ...userData, state: e.target.value })}
 />
 
        <TextField
          id="outlined-number"
          label="Number"
          name='mobileNumber'
          value={userData.mobileNumber}
          type="number"
   onChange={(e) => setUserData({ ...userData, number: e.target.value })}

          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="outlined-city"
          label="City"
          name='city'
          value={userData.city}
   onChange={(e) => setUserData({ ...userData, city: e.target.value })}
        />
        <TextField
          id="outlined-select-currency-native"
         
          label="Cutting"
          name='cutting'
          value={userData.cutting}
   onChange={(e) => setUserData({ ...userData, cutting: e.target.value })}

        />
         <TextField
          id="outlined-select-currency-native"
          label="Role (Not Editable)"
          name='role'
          value={userData.role}
          onChange={(e) => setUserData({ ...userData, role: e.target.value })}
          InputProps={{ readOnly: true }}
        />
        <div className="user-button">
          <Button
            type="submit"
            sx={{
              bgcolor: '#1976D2',
              color: '#fff',
              marginTop: 2,
              ':hover': { bgcolor: '#2688e9' },
            }}
          >
            Submit
          </Button>
        </div>
                   </div>
    </Box>
  );
};

export default UpdateUserForm;
