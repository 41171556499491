import React from 'react'

import { Box } from '@mui/material'

import Card from '@mui/material/Card';

import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';
import ResellerNavBar from '../reseller_navigation/reseller_nav_bar';
import ResellerSidenav from '../reseller_navigation/reseller_side_navigation';
import ResellerUserListTable from './reseller_user_table';
import UserForm from '../../Forms/Form';
import CreatUserDialog from '../../Forms/button.Dialog';



const ResellerUser = () => {
  return (
    <>
      <ResellerNavBar/>
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <ResellerSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CreatUserDialog/>
          <Card id='gradientCard'  >
            <ResellerUserListTable />
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default ResellerUser
