import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Tooltip, Dialog, DialogTitle, Box, LinearProgress, Typography } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';

const AudioPlayer = ({ audioData }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const audioRef = useRef(null);
  const playbackPositionRef = useRef(0);

  useEffect(() => {
    const currentAudioRef = audioRef.current;

    if (currentAudioRef) {
      currentAudioRef.addEventListener('timeupdate', handleTimeUpdate);
      currentAudioRef.addEventListener('loadeddata', handleLoadedData);
      currentAudioRef.addEventListener('ended', handleClose);

      return () => {
        currentAudioRef.removeEventListener('timeupdate', handleTimeUpdate);
        currentAudioRef.removeEventListener('loadeddata', handleLoadedData);
        currentAudioRef.removeEventListener('ended', handleClose);
      };
    }
  }, [audioRef]);
  

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleLoadedData = () => {
    setDuration(audioRef.current.duration);
    setIsLoaded(true);
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
      playbackPositionRef.current = audioRef.current.currentTime;
    } else {
      audioRef.current.currentTime = playbackPositionRef.current;
      audioRef.current.play();
      setOpen(true);
    }
    setIsPlaying(!isPlaying);
  };

  const handleClose = () => {
    setOpen(false);
    audioRef.current.pause();
    setIsPlaying(false);
    setCurrentTime(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <audio ref={audioRef} src={`data:audio/mpeg;base64,${audioData}`} />
      <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
        <IconButton onClick={togglePlay} color="primary">
          {isPlaying ? <Pause /> : <PlayArrow />}
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Audio Progress</DialogTitle>
        {isLoaded && (
          <Box sx={{ p: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(currentTime / duration) * 100}
              sx={{ height: 8, mb: 2 }}
            />
            <Typography variant="body2" align="center">
              {formatTime(currentTime)} / {formatTime(duration)}
            </Typography>
          </Box>
        )}
      </Dialog>
    </div>
  );
};

export default AudioPlayer;
