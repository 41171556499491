import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Input, CardContent, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import obdForm from "../css/obdForm.css"
import LoadingOverlay from '../../loader/loader';
import Alert from '@mui/material/Alert';






const CreateResellerCampaignForm=()=>{

  const number = 1234567890;

  // Function to download the number as a CSV file
  const downloadCSV = () => {
    const blob = new Blob([number], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'numbers.csv';
    link.click();
};

const [loading, setLoading] = useState(true);
const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState('');
  const [selectedPlan, setSelectedPlan] = useState('0');
  const [error, setError] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && !file.type.includes('audio/mpeg')) {
      setError('Only .mp3 files are allowed.');
      setSelectedFile(null);
    } else {
      setError('');
      setSelectedFile(file);
    }
  };

  const handleCsvFileChange = (event) => {
    const file = event.target.files[0];

    if (file && !file.type.includes('.csv')) {
      setError('Only .csv files are allowed.');
      setSelectedFile(null);
    } else {
      setError('');
      setSelectedFile(file);
    }
  };


  


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(value === 'none' ? '' : value);
  };

const [value , setValue]=useState(0)
// const navigate= Navigate()
const navigate = useNavigate();
// const [campaignType, setCampaignType] = useState('');

const handlePlanChange = (event) => {
  setSelectedPlan(event.target.value); // Update the selected plan when changed
};

  const handleSubmit=async(e)=>{
    setIsLoading(true);
      e.preventDefault()
      if (!selectedFile) {
        setError('Please select an .mp3 file.');
        return;
      }
      const formData= new FormData(e.currentTarget)
      const newCampiagn= Object.fromEntries(formData)
      try {
          const token = localStorage.getItem('token');
       const response=   await axios.post(
              'obd/create-obd',       
              newCampiagn,
              {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      authorization: token,
                    },
                }
                );

             
                setIsLoading(false);
                alert('Campaign created successfully');

                navigate('/reseller-obd-list')  
             
          
    } catch (error) {
      alert('Failed to create campaign. Please try again later.');
      navigate('/reseller-obd-list') 
    }

  }
  return(
    <>
    <LoadingOverlay loading={isLoading}>
    <div>
    
 
<form className='form' onSubmit={handleSubmit}>
  <h4>Create Campaign </h4>
 
 
  <div className='form-row'>
    <label htmlFor="Campaign Name" className='form-label'>
      Campaign Name
    </label>
    <input type="text" className='form-input' id='CampaigName' name='CampaigName' />
  </div>
 
  <div className='form-row'>
    <label htmlFor="Description" className='form-label'>
      Campaign Description
    </label>
    <input type="text" className='form-input' id='description' name='description' />
  </div>
  {/* <div className='form-row'>
      <InputLabel id="plan-label">Select Plan</InputLabel>
      
      <Select
        labelId="plan-label"
        id="plan"
        value={selectedPlan}
        label="Select Plan"
        onChange={handlePlanChange}
      >
        <MenuItem value="0"> </MenuItem>
        <MenuItem value="1">15-Second Plan</MenuItem>
        <MenuItem value="2">15-Second Answer</MenuItem>
        <MenuItem value="3">30-Second Plan</MenuItem>
        <MenuItem value="4">30-Second Answer</MenuItem>

      </Select>
    </div> */}

  {/* <div className='form-row'>
    <label htmlFor="audioFile" className='form-label'>
      Upload Campaign Audio
    </label>
    <input type="file" accept=".mp3, audio/mpeg" className='form-input' id='audioFile' name='audioFile' />
  </div> */}
   {error && (
          <Alert severity="error">{error}</Alert>
        )}

<div className='form-row'>
        <label htmlFor="audioFile" className='form-label'>
          Upload Campaign Audio (MP3 only)
        </label>
        <input
          type="file"
          accept=".mp3, audio/mpeg"
          className='form-input'
          id='audioFile'
          name='audioFile'
          onChange={handleFileChange}
        />
        </div>

 
<div className='form-row'>
    <label htmlFor="numberFile" className='form-label'>
      Upload Campaign Number File (only csv  accept)
    </label>
    <input type="file" accept='.csv,.xlsx' className='form-input' id='numberFile' name='numberFile' 
          onChange={handleCsvFileChange}
          />
  </div>
  <Button variant="contained" onClick={downloadCSV} sx={{ marginBottom: '20px',width:'23%',fontSize:'10px' }}>
        Download Sample CSV
      </Button>
  <div>
  <div className="form-row">
<TextField
                      id="numbers"  
                      name="numbers"
                      label="Enter Manual Numbers (One per line) Or Upload CSV"
                      multiline
                      rows={8}
                      fullWidth
                  />
           </div> 
    </div>
  <button type='submit'>Create Campaign</button>
</form>

    </div>
    </LoadingOverlay>
    </>
  )
}
export default CreateResellerCampaignForm;


// import React, { useState } from 'react';
// import { Button, Card, Typography, Box, TextField } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import axios from '../../api/axios';
// import LoadingOverlay from '../../loader/loader';

// const CreateResellerCampaignForm = () => {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);


//     const number = 1234567890;

//   // Function to download the number as a  CSV file
//   const downloadCSV = () => {
//     const blob = new Blob([number], { type: 'text/csv' });
//     const link = document.createElement('a');
//     link.href = window.URL.createObjectURL(blob);
//     link.download = 'numbers.csv';
//     link.click();
// };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData(e.currentTarget);
//     const newCampaign = Object.fromEntries(formData);
//     try {
//       const token = localStorage.getItem('token');
//       const response = await axios.post(
//         'obd/create-obd',
//         newCampaign,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Authorization: token,
//           },
//         }
//       );

//       alert('Campaign created successfully');
//       navigate('/reseller-obd-list');
//     } catch (error) {
//       alert('Failed to create campaign. Please try again later.');
//       navigate('/reseller-obd-list');
//     }
//   };

//   return (
//     <>
//       <div>
//         <form className="form" onSubmit={handleSubmit}>
//           <h4>Create Campaign</h4>

//           <div className="form-row">
//             <label htmlFor="campaignName" className="form-label">
//               Campaign Name
//             </label>
//             <input type="text" className="form-input" id="campaignName" name="campaignName" />
//           </div>

//           <div className="form-row">
//             <label htmlFor="description" className="form-label">
//               Campaign Description
//             </label>
//             <input type="text" className="form-input" id="description" name="description" />
//           </div>

//           <div className="form-row">
//             <label htmlFor="audioFile" className="form-label">
//               Upload Campaign Audio
//             </label>
//             <input type="file" accept=".mp3, audio/mpeg" className="form-input" id="audioFile" name="audioFile" />
//           </div>

//           <div className="form-row">
//           {/* <TextField
//                       id="numbers"  
//                       name="numbers"
//                       label="Enter Manual Numbers (One per line) Or Upload CSV"
//                       multiline
//                       rows={8}
//                       fullWidth
//                     /> */}
//           </div>

//           <div className="form-row">
//             <label htmlFor="numberFile" className="form-label">
//               Upload Campaign Number File (CSV only)
//             </label>
//             <input type="file" accept=".csv, .xlsx" className="form-input" id="numberFile" name="numberFile" />
//           </div>
//           <Button variant="contained" onClick={downloadCSV} sx={{ marginBottom: '20px', width: '20%', fontSize: '10px' }}>
//             Download Sample CSV
//           </Button>

       

         

//           <button type="submit">Create Campaign</button>
//         </form>
//       </div>
//     </>
//   );
// };

// export default CreateResellerCampaignForm;

