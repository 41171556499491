import React ,{ useState, useEffect }from 'react'

import { Box } from '@mui/material'
import Typography from '@mui/material/Typography';
import NavBar from '../../components/navBar';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import "../../css/dash.css"
import PersonIcon from '@mui/icons-material/Person';
import CampaignIcon from '@mui/icons-material/Campaign';
import CoPresentIcon from '@mui/icons-material/CoPresent';
// import UserSidenav from '../../components/sidenav';
import UserNavBar from '../UserNavigation/user_nav_bar';
// import UserSidenav from '../UserNavigation/userSideNav';
import UserSidenav from '../UserNavigation/userSideNav';
import axios from '../../api/axios';
const UserDashboard = () => {


  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBackendData();
  }, []);

  const fetchBackendData = async () => {
    try {
      const response = await axios.get('obd/reseller-details',{
        headers: {
          authorization: token,
        },
      }); 
      setData(response.data);
      console.log("Response:", response.data.resellerCount); // Log the response data
    } catch (error) {
      console.error('Error fetching data:', error); // Log any errors
    }  finally {
            setLoading(false); // Set loading to false regardless of success or error
          }
        }
  return (
    <>
    <UserNavBar/>
    <Box height={70}/>
    <Box sx={{ display: 'flex' }}>
    <UserSidenav/>
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Grid container spacing={2}>
        <Grid item xs={8}>
        <Stack spacing={2} direction={"row"}>
        {/* <Card sx={{ minWidth: 33 +"%", height:120  }} id='gradientCard'  >
      <CardContent>
        <div className='icon'>
        <PersonIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
  
          
        $900.00
        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
    Total Credit
    
        </Typography>
      </CardContent>
    </Card> */}
    <Card  sx={{ minWidth: 33 +"%", height:120 }} id='gradientCard'>
      <CardContent>
      <div className='icon'>
        <CampaignIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
    {data.userCampaignCount}
        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
       Total Campaign
        </Typography>
      </CardContent>
    </Card>
    {/* <Card sx={{ minWidth: 33 +"%", height:120}} id='gradientCard'>
      <CardContent>
      <div className='icon'>
        <CoPresentIcon/>
        </div>
        <Typography gutterBottom variant="h6" component="div" className='card-title'>
        500
        </Typography>
        <Typography gutterBottom variant="body2" component="div" className='card-text'>
        Credit
        </Typography>
      </CardContent>
    </Card> */}
    </Stack>
        </Grid>
      </Grid>

       
      </Box>
    </Box>
    

    </>
   
  )
}
export default UserDashboard
