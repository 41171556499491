import { createContext , useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";

const AuthContext=createContext({})
 
export const AuthProvider=({children})=>{
    
    const[auth, setAuth]= useState({});
const logout = () => {
    setAuth({});
 localStorage.clear('token');

    window.location.href = '/'; 
  };

    return(
        <AuthContext.Provider value={{auth, setAuth,logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
