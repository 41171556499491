import React from 'react'
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignListTable from '../../tables/obdTable';
import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';
import ResellerNavBar from '../reseller_navigation/reseller_nav_bar';
import ResellerSidenav from '../reseller_navigation/reseller_side_navigation';
import ResellerCampaignListTable from './reseller_obd_table';


const ResellerObd = () => {
  return (
    <>
      <ResellerNavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <ResellerSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <div className='user-button'>
            <Link to="/reseller-create-obd" style={{ textDecoration: 'none' }}>
              <ReUseableButton style={{ background: '#1976D2', margin: '0 0 24px 0', color: '#fff', fontFamily: 'Inter', fontSize: 13 }} >
                Create Campaign
              </ReUseableButton>
            </Link>

          </div>
          <Card id='gradientCard'  >
            <ResellerCampaignListTable />
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default ResellerObd
