import React, { useRef, useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
// import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from '../api/axios';
const LOGIN_URL= 'user-login'

const Login = () => {
  const errRef   =useRef()
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');;
  const defaultTheme = createTheme();
  const [error, setError] = useState(null);

  const handleClose = () => {
    setError(null);
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    try {

      const response = await axios.post(LOGIN_URL,
        JSON.stringify({username, password }),
      {  headers: { 
              'Content-Type': 'application/json',
            },
          }
        );
        const accessToken= response?.data?.token;
        const User=response?.data?.role
        localStorage.setItem('token', accessToken);
        localStorage.setItem('User', JSON.stringify(User));
        const UserRole=User

        if(UserRole==="reseller"){          
        navigate('/reseller-dashboard')   
        }else if(UserRole==="user"){
        navigate('/user-Dashboard') 
        }else if(UserRole ==="admin"){
        navigate('/dashboard') 
        }else{
        setError('Login failed')
        }

        // navigate('/dashboard')        
    } catch (error) {
      if (!error?.response) {
        setError('No server Response',error);
      } else if (error.response?.status === 400) {
        setError('Missing Username or Password');
  
        // Display a simple popup for invalid username or password
        window.confirm('Invalid username or password. Please try again.');
        
        // Check if errRef is defined before using focus
        if (errRef && errRef.current) {
          errRef.current.focus();
        }
      } else if (error.response?.status === 401) {
        setError('Unauthorized');
      } else {
        setError('Login failed');
      }
    }
  };

  return (
    <>


<ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    
   

    <Dialog open={!!error} onClose={handleClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{error}</DialogContent>
        <Button onClick={handleClose}>Close</Button>
      </Dialog>
    </>
   
  );
};

export default Login;
