
  const generate_User_Report_CSV = (data, selectedColumns, fileName) => {
    const filteredData = data.map(row =>
      selectedColumns.reduce((acc, column) => {
        acc[column] = row[column] || '';
        return acc;
      }, {})
    );
    const csvContent = [
      selectedColumns.join(','), // CSV header row
      ...filteredData.map(row => selectedColumns.map(column => row[column]).join(',')) // CSV data rows
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName || 'download.csv';
  
    // Append the link to the DOM and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
  
    // Clean up: Remove the dynamically created link from the DOM
    document.body.removeChild(downloadLink);
  };



//   const generate_User_Report_CSV = (data, selectedColumns, customColumnNames, fileName) => {
//     const filteredData = data.map(row =>
//         selectedColumns.reduce((acc, column) => {
//             acc[customColumnNames[column] || column] = row[column] || '';
//             return acc;
//         }, {})
//     );
    
//     const csvContent = [
//         Object.values(customColumnNames || {}).join(','), // Custom CSV header row
//         ...filteredData.map(row => selectedColumns.map(column => row[column]).join(',')) // CSV data rows
//     ].join('\n');
//     console.log("CSV Content:", csvContent);
    
//     const blob = new Blob([csvContent], { type: 'text/csv' });
//     const downloadLink = document.createElement('a');
//     downloadLink.href = URL.createObjectURL(blob);
//     downloadLink.download = fileName || 'download.csv';
    
//     // Append the link to the DOM and trigger the download
//     document.body.appendChild(downloadLink);
//     downloadLink.click();
    
//     // Clean up: Remove the dynamically created link from the DOM
//     document.body.removeChild(downloadLink);
// };


  const generate_Report_CSV = (data, selectedColumns, fileName) => {
    const filteredData = data.map(row =>
      selectedColumns.reduce((acc, column) => {
        acc[column] = row[column] || '';
        return acc;
      }, {})
    );
    const csvContent = [
      selectedColumns.join(','), // CSV header row
      ...filteredData.map(row => selectedColumns.map(column => row[column]).join(',')) // CSV data rows
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName || 'download.csv';
  
    // Append the link to the DOM and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
  
    // Clean up: Remove the dynamically created link from the DOM
    document.body.removeChild(downloadLink);
  };

  export {generate_User_Report_CSV,generate_Report_CSV };