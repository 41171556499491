import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  Box,
} from '@mui/material';
import Alert from '@mui/material/Alert';

import Sidenav from '../../components/sidenav';
import NavBar from '../../components/navBar';
import LoadingOverlay from '../../loader/loader';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';

const Config = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState({
    wa_temp_token: '',
    wa_phone_id: '',
    wa_business_id: '',
  });
  const [errors, setErrors] = useState({});
  const getData = async () => {
    const allData = await axios.get('/wa-business-data');
    setFormData(
      {
        wa_temp_token: allData.data.data.wa_temp_token,
        wa_phone_id: allData.data.data.wa_phone_id,
        wa_business_id: allData.data.data.wa_business_id,
      }
    );

  }
  useEffect(() => {
    getData();
  },[]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.wa_temp_token = formData.wa_temp_token ? '' : 'This field is required.';
    tempErrors.wa_phone_id = formData.wa_phone_id ? '' : 'This field is required.';
    tempErrors.wa_business_id = formData.wa_business_id ? '' : 'This field is required.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  };
  const handleRedirect = () => {
    if(formData?.wa_business_id){
      window.open(
        `https://business.facebook.com/wa/manage/message-templates/?business_id=871729711277201&waba_id=${formData?.wa_business_id}`,
        '_blank'
      );
    }else{
      setAlertMsg('WhatsApp Business Account ID is missing')
    }
   
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setIsLoading(true);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/wa-business-data', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsLoading(false);
      setIsError(false)
      setAlertMsg('Data submitted successfully');
     
    } catch (error) {
      setIsLoading(false);
      console.error('Error submitting data:', error);
      setIsError(true)
      setAlertMsg('Failed to submit data');

    }
  };

  return (
    <>
      <NavBar />
      <Box sx={{ display: 'flex', marginTop: 10 }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <Card>
            <LoadingOverlay loading={isLoading}>
              {alertMsg && (
                <Alert severity={isError ? 'error': 'success'}>
                {alertMsg}
              </Alert>
              )}
              
              <Button variant="outlined" sx={{ marginTop: 2, marginLeft:4 }} onClick={handleRedirect}>
              Templates
            </Button>
              <Box sx={{ p: 4 }}>
                <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 4 }}>
                  Submit WhatsApp Business Data
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="wa_temp_token"
                      name="wa_temp_token"
                      label="WhatsApp Template Token"
                      value={formData.wa_temp_token}
                      onChange={handleChange}
                      error={!!errors.wa_temp_token}
                      helperText={errors.wa_temp_token}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="wa_phone_id"
                      name="wa_phone_id"
                      label="WhatsApp Phone ID"
                      value={formData.wa_phone_id}
                      onChange={handleChange}
                      error={!!errors.wa_phone_id}
                      helperText={errors.wa_phone_id}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="wa_business_id"
                      name="wa_business_id"
                      label="WhatsApp Business ID"
                      value={formData.wa_business_id}
                      onChange={handleChange}
                      error={!!errors.wa_business_id}
                      helperText={errors.wa_business_id}
                      fullWidth
                    />
                  </Box>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </form>
              </Box>
            </LoadingOverlay>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Config;
