import React, { useState } from 'react';
import { TextField, Button, Container, Typography, MenuItem, FormControl, InputLabel, Select, Box } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import axios from 'axios';

const SendMessage = () => {
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState(null); // State to hold the uploaded file

  const handlePhoneNumbersChange = (event) => {
    setPhoneNumbers(event.target.value);
  };

  const handleTemplateNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');

      const formData = new FormData();
      formData.append('numbers', phoneNumbers);
      formData.append('templateName', templateName);
      formData.append('type', type);
      formData.append('file', file); // Append the selected file to formData

      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('API response:', response.data);
      alert('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error gracefully
      // Optionally display an error message to the user
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h5" gutterBottom>
        Send WhatsApp Message
      </Typography>
      <TextField
        multiline
        rows={4}
        variant="outlined"
        label="Phone Numbers (one per line)"
        value={phoneNumbers}
        onChange={handlePhoneNumbersChange}
        fullWidth
        margin="normal"
      />
      <TextField
        variant="outlined"
        label="Template Name"
        value={templateName}
        onChange={handleTemplateNameChange}
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel htmlFor="type">Type</InputLabel>
        <Select
          value={type}
          onChange={handleTypeChange}
          label="Type"
          inputProps={{
            name: 'type',
            id: 'type',
          }}
        >
          <MenuItem value="video">Video</MenuItem>
          <MenuItem value="pdf">PDF</MenuItem>
          <MenuItem value="image">Image</MenuItem>
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" marginBottom="1rem">
        <input
          type="file"
          id="file-upload"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="file-upload">
          <Button variant="outlined" component="span" startIcon={<CloudUpload />}>
            Upload File
          </Button>
        </label>
      </Box>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Send Message
      </Button>
    </Container>
  );
};

export default SendMessage;
