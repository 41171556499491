import React from 'react'
import Sidenav from '../../components/sidenav'
import { Box } from '@mui/material'
// import NavBar from '../../components/navBar';
import Card from '@mui/material/Card';
import CampaignForm from "../../pages/OBD_Campiagn/Campaign_Form";
// import UserSidenav from '../UserNavigation/userSideNav';
import ResellerNavBar from '../reseller_navigation/reseller_nav_bar';
import ResellerSidenav from '../reseller_navigation/reseller_side_navigation';
import CreateResellerCampaignForm from './reseller_campiagn_form';





const ResellerCampaign = () => {
  return (
    <>
      <ResellerNavBar />
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <ResellerSidenav/>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Card  >
            <CreateResellerCampaignForm />
          </Card>
        </Box>
      </Box>


    </>

  )
}
export default ResellerCampaign
