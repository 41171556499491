import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import { TableCell } from '@mui/material';

const StatusComponent = ({ campaignRefIds }) => {
  const [statuses, setStatuses] = useState({}); // Initialize statuses state as an empty object

  useEffect(() => {
    // Check if campaignRefIds is defined and is an array
    if (!campaignRefIds || !Array.isArray(campaignRefIds)) {
      return; // Return early if campaignRefIds is not valid
    }

    const intervalId = setInterval(async () => {
      try {
        // Make a GET request to your backend API endpoint for each campaignRefId
        const statusPromises = campaignRefIds.map(async campaignRefId => {
          const response = await axios.get(`report/checkStatus?campaignRefId=${campaignRefId}`);
          return { [campaignRefId]: response.data.status };
        });

        // Resolve all promises
        const resolvedStatuses = await Promise.all(statusPromises);

        // Combine statuses into a single object
        const newStatuses = resolvedStatuses.reduce((acc, status) => {
          return { ...acc, ...status };
        }, {});

        // Update the statuses state with the new statuses
        setStatuses(newStatuses);
      } catch (error) {
        console.error('Error occurred while fetching statuses:', error);
      }
    }, 10 * 1000); // Run the interval every 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [campaignRefIds]); // Run the effect whenever campaignRefIds changes

  return (
    <>
      {campaignRefIds && campaignRefIds.map(campaignRefId => (
        <TableCell key={campaignRefId} sx={{ textAlign: "center", fontSize: 13 }} className='table-title'>
          {statuses[campaignRefId] || 'Pending'} {/* Display status from statuses state */}
        </TableCell>
      ))}
    </>
  );
};






export default StatusComponent;
