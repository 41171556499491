
import React from 'react'

import { Box } from '@mui/material'

import Card from '@mui/material/Card';

import ReUseableButton from '../../Assests/common_button';
import { Link } from 'react-router-dom';

import UserForm from '../../Forms/Form';
import CreatUserDialog from '../../Forms/button.Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import NavBar from '../../components/navBar';
import Sidenav from '../../components/sidenav'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import UserSidenav from '../UserNavigation/userSideNav';


import LoadingOverlay from '../../loader/loader';
import UserNavBar from '../UserNavigation/user_nav_bar';
export default function UserTransactionPage() {
    const navigate=useNavigate()
    const { userId } = useParams();
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
const token = localStorage.getItem('token')


  
    useEffect(() => {
      // Fetch transactions for the specified user using the userId parameter
      const fetchTransactions = async () => {
        try {
          const response = await axios.get('user-transactions',{
            headers:{
                authorization:token
            }
          });
          setTransactions(response.data); // Assuming the array is in response.data
          if(response.lenght==0){
            alert(" No Transactions Found")
            // navigate('/user')
          }
        } catch (error) {
          console.error('Error fetching transactions:', error);
        }finally {
            setLoading(false); // Set loading to false regardless of success or error
          }
      };
  
      fetchTransactions();
    }, [userId]);
   
  return (
    <>
    
      <UserNavBar/>
      <Box height={100} />
      <Box sx={{ display: 'flex' }}>
        <UserSidenav/>
        <LoadingOverlay loading={loading}>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {transactions.length > 0 ? (    
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 950 }} aria-label="caption table">
          <TableHead>
            <TableRow className='table-head'>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Sr. No</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>CreditAction</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Remarks</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Time</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Added By</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>balance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{textAlign:"center"}}>{index + 1}</TableCell>
                <TableCell sx={{textAlign:"center"}} component="th" scope="row">
                  {row.creditAction}
                </TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.remarks}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.time}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.addedBy}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.balance}</TableCell>

                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      ) : (
        <p>No transactions found for this user.</p>
      )}
        </Box>
        </LoadingOverlay>
      </Box>

     

    </>

  )
}

