import { useEffect } from "react";
import { Link,  useNavigate } from "react-router-dom";

function Protected(props){
    const {Component}=props
const navigate=useNavigate()
useEffect(()=>{
         let token=  localStorage.getItem('token');
         if(!token){
            navigate('/')
         }
     
})
    return(
        <div>
            <Component/>
        </div>
    )
}

export default Protected