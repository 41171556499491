import React from 'react'
import Sidenav from '../../components/sidenav';
import { Box } from '@mui/material'
import NavBar from '../../components/navBar';
import UserTable from '../../tables/userTabel';
import CreatUserDialog from '../../Forms/button.Dialog';
import Card from '@mui/material/Card';






const AdminUserList = () => {
  
  return (
      <>
      <NavBar/>
      <Box height={100}/>
      <Box sx={{ display: 'flex' }}>
        
      <Sidenav/>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <CreatUserDialog/>
        
      <Card  id='gradientCard'  >
      <UserTable/>
      </Card>
        </Box>
      </Box>
      

      </>
   
  )
}



export default AdminUserList
