// GetCredits.js
import React, { useEffect, useState } from 'react';
import axios from '../api/axios';
import ResellerNavBar from '../resller/reseller_navigation/reseller_nav_bar';
import UserNavBar from '../User/UserNavigation/user_nav_bar';
const GetCredits = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem('token');
  const userType = 'reseller'; // Replace this with your logic to determine user type

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('credits', {
          headers: {
            authorization: token,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle the error as needed
      }
    };

    fetchData();
  }, [token]);

  return (
    <>
    
    {userType === 'reseller' ? (
        <ResellerNavBar credits={data} />
      ) : (
        <UserNavBar credits={data} />
      )}
    
    </>
  );
};

export default GetCredits;
