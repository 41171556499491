import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from '../api/axios';
import { useNavigate } from 'react-router-dom';




const CREATE_URL = 'user-register'; // Replace with your actual backend API URL

const UserForm = () => {
    const [user, setUser] = useState({
    username: '',
    email: '',
    mobileNumber: 0,
    password: '',
    state: '',
    city: '',
    role: '', // default role
  });
  const handelInputs = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({ ...prevUser, [name]: name === 'mobileNumber' ? parseInt(value, 10) : value }));
  };
const navigate = useNavigate();
  const handleUserSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        CREATE_URL,
        user,
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
        }
      );

 
      alert("User Create successfully ")

      if (response.data.role === "reseller") {

        navigate('/reseller-user-list')
      }else if(response.data.role==="admin"){
        navigate('/user-list')
      } 
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const currencies = [
    { value: '', label: '' },
    { value: 'user', label: 'User' },
    { value: 'reseller', label: 'Re-seller' },
  ];
 
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleUserSubmit}
    >
      <div>
        <TextField
        //   required
          id="outlined-username-input"
          label="Username"
          autoComplete="off"
          name='username'
          value={user.username}
          onChange={handelInputs}
        />
        <TextField
        //   required
          id="outlined-email-input"
          type="email"
          label="Email"
          name='email'
          value={user.email}
          autoComplete="off"
          onChange={handelInputs}
        />
        <TextField
        //   required
          id="outlined-password-input"
          label="Password"
          type="password"
          name='password'
          value={user.password}
          autoComplete="current-password"
          onChange={handelInputs}
        />
        <TextField
        //   required
          id="outlined-state-input"
          label="State"
          name='state'
          value={user.state}
          onChange={handelInputs}
        />
        <TextField
          id="outlined-number"
          label="Number"
          name='mobileNumber'
          value={user.mobileNumber}
          type="number"
          onChange={handelInputs}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="outlined-city"
          label="City"
          name='city'
          value={user.city}
          onChange={handelInputs}
        />
        <TextField
      id="outlined-select-currency-native"
      select
      label="Role"
      name="role"
      value={user.role}
      onChange={handelInputs}
      SelectProps={{
        native: true,
      }}
      helperText="Please select your role"
    >
      {currencies.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
        <div className="user-button">
          <Button
            type="submit"
            sx={{
              bgcolor: '#1976D2',
              color: '#fff',
              marginTop: 2,
              ':hover': { bgcolor: '#2688e9' },
            }}
          >
            Submit
          </Button>
        </div>
                   </div>
    </Box>
  );
};

export default UserForm;
