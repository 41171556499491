import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import axios from '../api/axios';
import LoadingOverlay from '../loader/loader';

import { useNavigate } from 'react-router-dom';

const RePushButton = ({ campaignRefIds ,createBy }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('all');
const [isLoading, setIsLoading] = useState(false);
const navigate = useNavigate();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
console.log("selectedOption",createBy);
  const handleRePush = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('/obd/repush_campaign', {
        type: selectedOption,
        campaignRefId: campaignRefIds,
        createdById:createBy
      });
      console.log('Re-push successful:', response.data);
      handleClose();
      setIsLoading(false);
      if(response.data.status==200){
        alert('Campaign Re-created successfully');
        navigate('/reseller-obd-list') 
      }if(response.data.status==400){
        alert('Campaign is running Please wait for re-push');
        navigate('/reseller-obd-list')
      }
      
    } catch (error) {
      console.error('Error performing re-push:', error);
      // Handle error (e.g., display error message)
    }
  };

  return (
    <>
    <LoadingOverlay loading={isLoading}>

      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        startIcon={<ReplayIcon />}
        sx={{ fontSize: 9, fontWeight: 600, marginLeft: 1 }}
      >
        Re-push
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select Re-push Option</DialogTitle>
        <DialogContent>
          <RadioGroup value={selectedOption} onChange={handleOptionChange}>
            <FormControlLabel value="all" control={<Radio />} label="Re-Push to all" />
            <FormControlLabel value="answered" control={<Radio />} label="Re-Push to answered only" />
            <FormControlLabel value="unanswered" control={<Radio />} label="Re-Push to unanswered only" />
            <FormControlLabel value="Timeout" control={<Radio />} label="Re-Push to Timeout only" />

          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleRePush} color="primary" variant="contained">
            Re-push
          </Button>
        </DialogActions>
      </Dialog>
    </LoadingOverlay>

    </>
  );
};

export default RePushButton;
