import './App.css'
// import User from './User/User'
import Obd from './pages/OBD_Campiagn/Obd'
import Settings from './pages/settings'
import Login from './pages/login'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Dashboard from './pages/Dashboard/Dashboard'
import Protected from './auth/PrivateRoute'
import CreateCampaign from './pages/OBD_Campiagn/campaign'
import UserDashboard from './User/userDashboard/user_dashboard'
import UserObd from './User/User_OBD/user_obd'
import UserCampaign from './User/User_OBD/create_user_obd'
import ResellerDashboard from './resller/reseller_dashboard/reseller_dashboard'
import ResellerUser from './resller/reseller_user/reseller_user'
import ResellerObd from './resller/reseller_obd/reseller_obd'
import ResellerCampaign from './resller/reseller_obd/create_reseller_obd'
import AdminDashboard from './admin/admin_dashboard/admin_dashboard'
import AdminUserList from './admin/admin_user_list/User'
import AdminCreditUser from './admin/admin_credit/admin_credit'
import ResllerObdReport from './resller/reseller_report/resller_report'
import AdminEditProfile from './admin/profile/edit_profile_page'
import AdminTransactions from './admin/transaction/transcation'
import ResellerTransactionPage from './resller/transaction/reseller_transaction'
import UserTransactionPage from './User/transaction/user_transaction'
import AdminDni from './admin/dni/dni_numbers'
import AdminMangeProfile from './admin/profile/admin_profile'
import SendMessage from './pages/messageTemplate/sendmessage'
import Config from './pages/config/Config'

function App () {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Login />} />
          <Route
            path='/dashboard'
            exact
            element={<Protected Component={AdminDashboard} />}
          />
          {/* <Route path='/userDashboard' exact element={<UserDashboard/>} /> */}
          <Route
            path='/settings'
            exact
            element={<Protected Component={Settings} />}
          />
          <Route path='/user' exact element={<Protected Component={AdminUserList} />} />
          <Route path='/admin-credit-list' exact element={<Protected Component={AdminCreditUser} />} />
          <Route path='/update-user-profile' exact element={<Protected Component={AdminEditProfile} />} />
          <Route path='/transactions/:userId' exact element={<Protected Component={AdminTransactions} />} />
          <Route path='/dni' exact element={<Protected Component={AdminDni} />} />
          <Route path='/manage-profile' exact element={<Protected Component={AdminMangeProfile} />} />
          <Route path='/send-template-message' exact element={<Protected Component={SendMessage} />} />
          <Route path='/config' exact element={<Protected Component={Config} />} />






          <Route path='/obd' exact element={<Protected Component={Obd} />} />
          <Route path='/createCampaign' exact element={<Protected Component={CreateCampaign} />} />
          <Route path='/user-Dashboard' exact element={<Protected Component={UserDashboard} />} />
          <Route path='/user-transaction' exact element={<Protected Component={UserTransactionPage} />} />

          <Route path='/user-campaign-list' exact element={<Protected Component={UserObd} />} />
          <Route path='/create-user-campaign' exact element={<Protected Component={UserCampaign} />} />
          <Route path='/reseller-dashboard' exact element={<Protected Component={ResellerDashboard} />} />\
          <Route path='/reseller-user-list' exact element={<Protected Component={ResellerUser} />} />  
          <Route path='/reseller-obd-list' exact element={<Protected Component={ResellerObd} />} />    
          <Route path='/reseller-create-obd' exact element={<Protected Component={ResellerCampaign} />} />
          <Route path='/reseller-obd-report' exact element={<Protected Component={ResllerObdReport} />} />
          <Route path='/reseller-transaction-list/:userId' exact element={<Protected Component={ResellerTransactionPage} />} />


             










        </Routes>
      </BrowserRouter>
      {/* <Sidenav /> */}
    </>
  )
}

export default App
