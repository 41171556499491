import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import GetAppIcon from "@mui/icons-material/GetApp";
// import "../css/userTable.css"
import axios from "../../api/axios";
import { generate_User_Report_CSV } from "../../api/csv_handler";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import LoadingOverlay from "../../loader/loader";
import AudioPlayer from "../../tables/obdAudio";
import StatusComponent from "../../Assests/status";
import { Snackbar } from '@mui/material';
import RePushButton from "../../Assests/re-pushService";



export default function ResellerCampaignListTable({UID}) {

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
const token = localStorage.getItem('token')
  const handleDownloadUserReport = async (campaign_ref_Id) => {
    setLoading(true);
    try {
   
      const response = await axios.get(
        `report/user-generate-csv?campaignRefId=${campaign_ref_Id}`,
        {
          headers:{
            authorization:token
          }
        }
      );
      const data = response.data;
  console.log("data",data);
      
      if (response.status === 200) {
        const fileName = `report_${campaign_ref_Id}.csv`;
        const selectedColumns = ["A_PARTY_NO", "A_DIAL_STATUS", "obdcampaignname","creditAction","createdAt"];
        generate_User_Report_CSV(response.data, selectedColumns, fileName);
      } else if(response.data.status ===404) {
        alert('Campaign is in Pending Status');
      }
    } catch (error) {
      
      console.error("Error downloading report:", error);
      console.error("Error initiating CSV download");
    }
    finally {
      setLoading(false);
    }
  };

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBackendData();
  }, []);
  const fetchBackendData = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const OBD_URL = "reseller-list";
      const response = await axios.get(OBD_URL, {
        headers: {
          authorization: token,
        },
      });
      // console.log(response.data);
      setData(response.data); // Assuming the array is in response.data
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally {
      setLoading(false);
    }
  };



  return (<>
    <LoadingOverlay loading={loading}>

    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow className="table-head">
            <TableCell sx={{ textAlign: "center" }} className="table-title">
              Sr. No
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="table-title">
              Campaign Name
            </TableCell>
            {/* <TableCell sx={{ textAlign: "center" }} className="table-title">
              Campaign Type
            </TableCell> */}
            <TableCell sx={{ textAlign: "center" }} className="table-title">
              Audio
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="table-title">
              Status
            </TableCell>
            <TableCell sx={{ textAlign: "center" }} className="table-title">
              Report
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell sx={{ textAlign: "center" }}>{index + 1}</TableCell>{" "}
              {/* Add 1 to start from 1 */}
              <TableCell
                sx={{ textAlign: "center" }}
                component="th"
                scope="row"
              >
                {row.obdcampaignname}
              </TableCell>
              {/* <TableCell sx={{ textAlign: "center" }}>
                {row.campaign_Type}
              </TableCell> */}
              <TableCell sx={{ textAlign: "center" }}>
              <IconButton>
         <AudioPlayer audioData={row.audio.data} />
        </IconButton>
                {row.audio.filename}
              </TableCell>
              <TableCell sx={{ textAlign: "center" }}><StatusComponent campaignRefIds={[row.campaign_ref_Id]} /></TableCell>
              <TableCell sx={{ textAlign: "center" }}>
                <Button 
                  sx={{ fontSize: 9, fontWeight: 600, marginLeft: 1 }}
                  variant="outlined"
                  color="primary"
                  onClick={() => handleDownloadUserReport(row.campaign_ref_Id)}
                 
                  
                >
                  <DownloadIcon sx={{ fontSize: 18, marginRight: 1 }} />{" "}
                  Download
                </Button>
                <RePushButton campaignRefIds={[row.campaign_ref_Id]} createBy={[row.createdBy]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </LoadingOverlay>

   
    </>
    
  );
}



