import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button ,Dialog,DialogTitle,DialogContent,FormControlLabel,Checkbox,TextField} from "@mui/material";
import axios from "../../api/axios";

export default function AdminUserListTable() {
  const token= localStorage.getItem('token')
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [addCreditChecked, setAddCreditChecked] = useState(false);
  const [removeCreditChecked, setRemoveCreditChecked] = useState(false);
  const [creditAmount, setCreditAmount] = useState('');

  const handleEditIconClick = (userId) => {
    setOpenPopup(true);
    setSelectedUserId(userId);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setAddCreditChecked(false);
    setRemoveCreditChecked(false);
    setCreditAmount('');
  };

  const handleCheckboxChange = (type) => {
    if (type === 'add') {
      setAddCreditChecked(true);
      setRemoveCreditChecked(false);
    } else {
      setRemoveCreditChecked(true);
      setAddCreditChecked(false);
    }
  };

  const handleApplyChanges = () => {
    // Validate credit amount (only numbers, no decimals, no alphabets)
    const isNumeric = /^\d+$/.test(creditAmount);
    
    if (isNumeric) {
      // Call your API to add or remove credit based on checkbox values and credit amount
      const apiEndpoint = addCreditChecked ? '/update_credits' : '/reverse_credit_transfer';
    const requestBody = {
      userId: selectedUserId,
      credits: parseInt(creditAmount, 10)  // Convert creditAmount to an integer
    };

    // Call your API to add or remove credit based on checkbox values and credit amount
    axios.put(apiEndpoint,requestBody,{  headers: {
      authorization: token,
    }})
      .then(() => {
        alert('Credit Edit successfully');
        window.location.reload();
        handleClosePopup();

      })
      .catch(error => {
        alert('error',error)
        console.error('API error:', error);
      });
    } else {
      // Show an error message or handle the invalid input as needed
      console.error('Invalid credit amount. Please enter a valid number.');
    }
  };


  const [data, setData] = useState([]);
  useEffect(() => {
    fetchBackendData();
    
  }, []);
  const fetchBackendData = async () => {
    try {
      const OBD_URL = 'userList';
      const response = await axios.get(OBD_URL, {
        headers: {
          authorization: token,
        },
      });
      setData(response.data); // Assuming the array is in response.data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow className="table-head">
            <TableCell className="table-title">Sr. No</TableCell>
            <TableCell className="table-title">User Name</TableCell>
            <TableCell className="table-title">User Type</TableCell>
            <TableCell className="table-title">Credit</TableCell>
            <TableCell className="table-title">Action</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell> {/* Add 1 to start from 1 */}
              <TableCell component="th" scope="row">
                {row.username}
              </TableCell>
              <TableCell>{row.role}</TableCell>
              <TableCell>{row.credits}</TableCell>
              

              <TableCell> <Button variant="outlined" color="primary" onClick={() => handleEditIconClick(row._id)}>
                    Edit
                  </Button></TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle>Edit Credits</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={addCreditChecked}
                onChange={() => handleCheckboxChange('add')}
                name="addCredit"
                color="primary"
              />
            }
            label="Add Credit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={removeCreditChecked}
                onChange={() => handleCheckboxChange('remove')}
                name="removeCredit"
                color="secondary"
              />
            }
            label="Remove Credit"
          />
          {addCreditChecked || removeCreditChecked ? (
            <TextField
              label="Credit Amount"
              variant="outlined"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value.replace(/\D/, ''))}
            />
          ) : null}
          <Button variant="contained" color="primary" onClick={handleApplyChanges}>
            Apply Changes
          </Button>
        </DialogContent>
      </Dialog>

    </>
  );
}


