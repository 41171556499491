import React, { useState } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
} from '@mui/material';
import Sidenav from '../../components/sidenav';
import NavBar from '../../components/navBar';
import LoadingOverlay from '../../loader/loader';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import TokenUploader from './tokenUploader';
import Alert from '@mui/material/Alert';


const CreateCampaignForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedMediaType, setSelectedMediaType] = useState('text');
  const [alertMsg, setAlertMsg] = useState('');
  const [isError, setIsError] = useState(false);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleMediaTypeChange = (event) => {
    setSelectedMediaType(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.currentTarget);
    formData.append('language', selectedLanguage);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      setIsLoading(false);
     setIsError(false)
      setAlertMsg(response?.data?.message);
     
    } catch (error) {
      setIsLoading(false);
      console.error('Error uploading media:', error);
      setIsError(true)
      setAlertMsg(`Message Sending failed: ${error.response.data.message}`)
    }
  };

 

  return (
    <>
      <NavBar />
      <Box sx={{ display: 'flex', marginTop: 10 }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Card>
         
            <LoadingOverlay loading={isLoading}>
            {alertMsg && (
                <Alert severity={isError ? 'error': 'success'}>
                {alertMsg}
              </Alert>
              )}
              <Box sx={{ p: 4 }}>
                <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 4 }}>
                  Send Whatsapp Business Message
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="media-type-label">Media Type</InputLabel>
                      <Select
                        labelId="media-type-label"
                        id="type"
                        name="type"
                        value={selectedMediaType}
                        label="Media Type"
                        onChange={handleMediaTypeChange}
                      >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="video">Video</MenuItem>
                        <MenuItem value="document">Document</MenuItem>
                        <MenuItem value="image">Image</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="numbers"
                      name="numbers"
                      label="Phone Numbers (One per line)"
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      id="template_name"
                      name="template_name"
                      label="Template Name"
                      fullWidth
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel id="language-label">Language</InputLabel>
                      <Select
                        labelId="language-label"
                        id="language"
                        value={selectedLanguage}
                        label="Language"
                        onChange={handleLanguageChange}
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="hi">Hindi</MenuItem>
                        <MenuItem value="mr">Marathi</MenuItem>
                        <MenuItem value="gu">Gujarati</MenuItem>
                        <MenuItem value="kn">Kannada</MenuItem>
                        <MenuItem value="ml">Malayalam</MenuItem>
                        <MenuItem value="pa">Punjabi</MenuItem>
                        <MenuItem value="ta">Tamil</MenuItem>
                        <MenuItem value="te">Telugu</MenuItem>
                        <MenuItem value="ur">Urdu</MenuItem>
                        <MenuItem value="bn">Bengali</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {selectedMediaType !== 'text' && (
                    <Box sx={{ mb: 2 }}>
                      <input type="file" id="file" name="file" />
                    </Box>
                  )}
                  <Button variant="contained" type="submit">
                    Send Message
                  </Button>
                </form>
              </Box>
            </LoadingOverlay>
            </Card>
        </Box>
      </Box>
    </>
  );
};

export default CreateCampaignForm;
