import React from 'react'
import Sidenav from '../components/sidenav'
import { Box } from '@mui/material'
import NavBar from '../components/navBar';

const Setting = () => {
  return (
    <>
    <NavBar/>
    <Box height={30}/>
    <Box sx={{ display: 'flex' }}>
    <Sidenav/>

    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <h1>Settings</h1>
       
      </Box>
    </Box>
    

    </>
   
  )
}

export default Setting