import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Dialog, TextField,DialogTitle,DialogContent,DialogActions } from "@mui/material";
import axios from '../api/axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {FormControlLabel,Checkbox} from "@mui/material";
import { Link } from 'react-router-dom';
import {CircularProgress} from '@mui/material';
import LoadingOverlay from '../loader/loader';
import UpdateUser from '../admin/profile/edit_profile';
export default function UserListTable() {
  const token = localStorage.getItem('token');



  const [openCreditPopup, setCreditOpenPopup] = useState(false);
  const [CreditselectedUserId, setCreditSelectedUserId] = useState(null);
  const [addCreditChecked, setAddCreditChecked] = useState(false);
  const [removeCreditChecked, setRemoveCreditChecked] = useState(false);
  const [creditAmount, setCreditAmount] = useState('');


  const handleCreditEditIconClick = (userId) => {
    setCreditOpenPopup(true);
    setCreditSelectedUserId(userId);
  };

  const handleCreditClosePopup = () => {
    setCreditOpenPopup(false);
    setAddCreditChecked(false);
    setRemoveCreditChecked(false);
    setCreditAmount('');
  };

  const handleCheckboxChange = (type) => {
    if (type === 'add') {
      setAddCreditChecked(true);
      setRemoveCreditChecked(false);
    } else {
      setRemoveCreditChecked(true);
      setAddCreditChecked(false);
    }
  };

  const handleCreditApplyChanges = () => {
    // Validate credit amount (only numbers, no decimals, no alphabets)
    const isNumeric = /^\d+$/.test(creditAmount);
    
    if (isNumeric) {
      // Call your API to add or remove credit based on checkbox values and credit amount
      const apiEndpoint = addCreditChecked ? '/update_credits' : '/reverse_credit_transfer';
    const requestBody = {
      userId: CreditselectedUserId,
      credits: parseInt(creditAmount, 10)  // Convert creditAmount to an integer
    };

    // Call your API to add or remove credit based on checkbox values and credit amount
    axios.put(apiEndpoint,requestBody,{  headers: {
      authorization: token,
    }})
      .then(response => {
        alert('Credit Edit successfully');
        handleCreditClosePopup();
        window.location.reload();;
        
      })
      .catch(error => {
        console.error('API error:', error);
      });
    } else {
      // Show an error message or handle the invalid input as needed
      console.error('Invalid credit amount. Please enter a valid number.');
    }
  };





 

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBackendData();
  }, []);

  const USER_LIST = "userList";

  const fetchBackendData = async () => {
    try {
      const response = await axios.get(USER_LIST);
      setData(response.data); // Assuming the array is in response.data
    } catch (error) {
      console.error('Error fetching data:', error);
    }finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };
 
  return (
    <>

<LoadingOverlay loading={loading}>
    

           <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="caption table">
          <TableHead>
            <TableRow className='table-head'>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Sr. No</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Username</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Role</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Credit Balance</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Cutting</TableCell>
              <TableCell sx={{textAlign:"center"}} className='table-title'>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell sx={{textAlign:"center"}}>{index + 1}</TableCell>
                <TableCell sx={{textAlign:"center"}} component="th" scope="row">
                  {row.username}
                </TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.role}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.credits}</TableCell>
                <TableCell sx={{textAlign:"center"}}>{row.cutting_percentage}</TableCell>
                <TableCell sx={{textAlign:"center"}}>
                  <Button sx={{fontSize:9,fontWeight:600, marginLeft:1}} variant="outlined" color="primary" onClick={() => handleCreditEditIconClick(row._id)}>
                  Manage Credit
                  </Button>
                  <Link to={`/transactions/${row._id}`} style={{ textDecoration: 'none' }}>
                  <Button sx={{fontSize:9,fontWeight:600, marginLeft:1}} variant="outlined" color="primary">
                    Transaction History
                  </Button>
                  </Link>
                  <Button sx={{fontSize:9,fontWeight:600, marginLeft:1}}  >
                  <UpdateUser UID={row._id}/>
                  </Button>
  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
       

    
    </LoadingOverlay>
   

      {/* <Dialog open={openPopup} onClose={handleClosePopup} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Cutting</DialogTitle>
        <DialogContent>
          <TextField
            label="Cutting Amount"
            type="number"
            value={cuttingAmount}
            onChange={handleCuttingChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyChanges} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}



      <Dialog open={openCreditPopup} onClose={handleCreditClosePopup}>
        <DialogTitle>Edit Credits</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={addCreditChecked}
                onChange={() => handleCheckboxChange('add')}
                name="addCredit"
                color="primary"
              />
            }
            label="Add Credit"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={removeCreditChecked}
                onChange={() => handleCheckboxChange('remove')}
                name="removeCredit"
                color="secondary"
              />
            }
            label="Deduct Credit"
          />
          {addCreditChecked || removeCreditChecked ? (
            <TextField
              label="Credit Amount"
              variant="outlined"
              value={creditAmount}
              onChange={(e) => setCreditAmount(e.target.value.replace(/\D/, ''))}
            />
          ) : null}
          <Button variant="contained" color="primary" onClick={handleCreditApplyChanges}>
            Apply Changes
          </Button>
        </DialogContent>
      </Dialog>

    </>
  );
}
